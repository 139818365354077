<template>
  <div class="weakness_warp">
    <div class="left">
      <LeftController @initList="initList" />
    </div>
    <div class="right">

      <TopCard ref="TopCardRef"
               text="弱点训练"
               :showBack="false" />
      <div class="list">
        <div class="warp">
          <el-row v-for="(row,index) in tableData"
                  :key="index">
            <el-col :span="7">
              <div class="checkpoint">
                {{row.checkPointDesc || row.knowledgeDesc}}
              </div>
            </el-col>
            <el-col :span="3">
              <span class="score">得分率(%)：</span>
              <span style="color:#ED7178;font-size: 20rem;">{{row.rate}}</span>
            </el-col>

            <el-col :span="2">
              <span class="score">训练次数：</span>
              <span style="color:#ED7178;font-size: 20rem;">{{row.doQuestionCount}}</span>
            </el-col>
            <el-col :span="2">
              <span class="score">易错指数：</span>
              <span style="color:#ED7178;font-size: 20rem;"> {{row.errorProne}}</span>
            </el-col>

            <el-col :span="3">
              <div class="pl">
                星级:
                <img src="@/assets/icons/icon-星星.png"
                     v-for="(item,index) in row.checkPointStar || row.knowledgePointStar"
                     :key="index" />
              </div>
            </el-col>
            <el-col :span="7">
              <div class="btn_warp">
                <div class="btn"
                     v-if="row.hasKnowledgePoint2"
                     @click="showOther(row.child)">
                  二级考点
                </div>
                <div class="btn"
                     v-if=" row.hasKnowledgePoint3"
                     @click="showOtherThird(row.child)">
                  三级考点
                </div>

                <div class="btn"
                     v-if="userInfo.edu_grade_id != 1 && row.videoCount>0"
                     @click="showVideo(row)">
                  <!-- {{?'考点讲解':'考点讲解'}} -->
                  考点讲解
                </div>
                <div class="btn"
                v-if="row.lecture_data.length>0"
                     @click="viewLecture(row)">
                  查看讲义
                </div>
                <div class="btn"
                     @click="doPaper(row)">
                  开始训练
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <VideoForm ref="VideoFormRef" />
    <LectureForm ref="LectureFormRef" />
    <Other ref="OtherRef" />
    <OtherThird ref="OtherThirdRef" />
  </div>
</template>

<script>
import LeftController from '@/views/components/left_controller.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { getHomeRefrence, getHomeStudy } from '@/api/home.js'
import VideoForm from './components/video.vue'
import LectureForm from './components/lecture.vue'
import Other from './components/other.vue'
import OtherThird from './components/otherThird.vue'

export default {
  data () {
    return {
      tableData: [],
      userInfo: {},
      subject_id: ''
    }
  },
  components: {
    LeftController, TopCard, VideoForm, Other, OtherThird,LectureForm
  },
  methods: {
    async initList (id) {
      var userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      let params = {
        subjectId: id,
        show_lecture: true
      }
      this.subject_id = id

      // if (userInfo.edu_grade_id == 1) {
      //   const { data } = await getHomeRefrence(params)
      //   this.tableData = data
      //   this.userInfo = userInfo

      // } else {
      const { data } = await getHomeStudy(params)
      this.tableData = data
      // }
      // this.tableData = data.volist
    },
    showVideo (row) {
      let params
      // if (this.userInfo.edu_grade_id == 1) {
      //   params = {
      //     check_point_id: row.checkPointId
      //   }
      //   this.$refs.VideoFormRef.text = '考点'
      // } else {
      params = {
        id: row.knowledgeId || row.chapter_id
      }
      this.$refs.VideoFormRef.text = '考点'
      // }
      console.log('ss8888',this.userInfo.edu_grade_id);
      
      this.$refs.VideoFormRef.initList(params, this.userInfo.edu_grade_id)
      this.$refs.VideoFormRef.dialogVisible = true
    },
    showOther (row) {

      this.$refs.OtherRef.initList(row)
      this.$refs.OtherRef.dialogVisible = true
      this.$refs.OtherRef.type = 9
    },

    showOtherThird (row) {
      this.$refs.OtherThirdRef.initList(row)
      this.$refs.OtherThirdRef.dialogVisible = true
      this.$refs.OtherThirdRef.type = 9
    },
    viewLecture (row) {
      
    
      this.$refs.LectureFormRef.text = '讲义数据'
      this.$refs.LectureFormRef.initList(row.lecture_data)
      this.$refs.LectureFormRef.dialogVisible = true

    },
    doPaper (row) {
      // if (this.userInfo.edu_grade_id != 1) {
      let params = {
        subject_id: this.subject_id,
        knowledge_id: row.knowledgeId,
        is_weak: 1,
        type: 5,
      }
      this.$router.push('/doPaper?type=5&form=' + JSON.stringify(params))
      // return
      // }
      // let params = {
      //   check_point_id: row.checkPointId,
      //   selecting_checkpoint: 0,
      //   type: 8,
      // }
      // this.$router.push('/doPaper?type=3&form=' + JSON.stringify(params))
    }
  }
}
</script>

<style lang="scss" scoped>
.weakness_warp {
  overflow: hidden;
  display: flex;
  .left {
    width: 100rem;
  }
  .right {
    width: calc(100% - 100rem);
    background: #f6f6f6;
    .tabs {
      margin-top: 22rem;
      display: flex;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 18rem;
      .tab {
        cursor: pointer;
        width: 260rem;
        height: 54rem;
        background: #2196f3;
        border-radius: 30rem;
        color: #ffffff;
        font-size: 24rem;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .list {
      width: 100%;
      max-height: calc(100vh - 60rem - 40rem);
      -ms-overflow-style: none;
      background: white;
      overflow-y: auto;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .el-row {
        height: 80rem;
        border-bottom: 1rem solid #f1f1f1;
        &:hover {
          background: rgba($color: #2196f3, $alpha: 0.1);
        }
      }
      div {
        height: 80rem;
        line-height: 80rem;
      }
      .checkpoint {
        font-size: 20rem;
        font-weight: bold;
        color: #000000;
        margin-left: 50rem;
      }
      .score {
        font-size: 20rem;
        font-weight: bold;
        color: #000000;
      }
      .pl {
        font-weight: bold;
        font-size: 20rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #666666;
        img {
          width: 24rem;
          height: 23rem;
          margin-left: 10rem;
        }
      }
      .btn_warp {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn {
          width: 100rem;
          height: 44rem;
          border-radius: 5rem;
          font-size: 20rem;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin: 0 10rem;
          &:nth-child(1) {
            color: #2196f3;
            background: #e7f0ff;
          }
          &:nth-child(2) {
            color: #2196f3;
            background: #e7f0ff;
          }
          &:nth-child(3) {
            // color: #ffffff;
            // background: #2196f3;
            // margin-right: 20rem;
            color: #2196f3;
            background: #e7f0ff;
          }
          &:nth-child(4) {
            color: #2196f3;
            background: #e7f0ff;
          }
          &:nth-child(5) {
            color: #2196f3;
            background: #e7f0ff;
          }
        }
      }
    }
  }
}
</style>