<template>
  <div>
    <el-dialog title="二级考点"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="1200rem"
               :before-close="handleClose">
      <el-table :data="tableData"
                height="520rem"
                ref="theTable"
                @row-click="getOpenDetail"
                :tree-props="{child: 'child'}"
                row-key="id"
                style="width: 100%">
        <el-table-column prop="text"
                         align="left"
                         label="考点">
          <template slot-scope="{row}">
            <div class="checkpoint">
              {{row.knowledgeDesc?row.knowledgeDesc:row.check_point_3_desc}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="rate"
                         align="left"
                         label=" 得分率">
          <template slot-scope="{row}">
            <div class="checkpoint">
              {{row.rate}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="do_question_count"
                         align="left"
                         v-if="type == 9"
                         label=" 训练次数">
          <template slot-scope="{row}">
            <div class="checkpoint">
              {{row.doQuestionCount}}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="text"
                         align="left"
                         label="易错指数">
          <template slot-scope="{row}">
            <div class="checkpoint">
              {{row.errorProne}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="500">
          <template slot-scope="{row}">
            <div class="btn"
                 v-if="row.videoCount>0"
                 @click="showVideo(row)">
              考点讲解
            </div>
            <div class="btn"
                 v-if="row.lecture_data.length>0"
                 @click="viewLecture(row)">
              讲义数据
            </div>
            <div class="btn"
                 @click="doPaper(row)">
              开始训练
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <LectureForm ref="LectureFormRef" />
    <VideoForm ref="VideoFormRef" />
  </div>
</template>
<script>
import LectureForm from './lecture.vue'
import VideoForm from './video.vue'

import { getTwoCheckpoint } from '@/api/checkpoint.js'
export default {
  data () {
    return {
      form: {},
      tableData: [],
      dialogVisible: false,
      type: ''
    }
  },
  components: {
    LectureForm, VideoForm
  },
  methods: {
    getOpenDetail (row) {
      this.$refs.theTable.toggleRowExpansion(row);
    },
    handleClose () {
      this.dialogVisible = false
    },
    viewLecture (row) {
      this.$refs.LectureFormRef.text = '二级讲义数据'
      this.$refs.LectureFormRef.initList(row.lecture_data)
      this.$refs.LectureFormRef.dialogVisible = true
    },
    showVideo (row) {
      let params

      params = {
        id: row.knowledgeId
      }
      this.$refs.VideoFormRef.text = '考点'
      this.$refs.VideoFormRef.initList(params, null)
      this.$refs.VideoFormRef.dialogVisible = true
    },
    doPaper (row) {
      console.log(row, 'row')
      let params = {
        subject_id: row.subjectId,
        knowledge_id: row.knowledgeId,
        is_weak: 1,
        type: 5,

      }

      this.$router.push('/doPaper?type=5&form=' + JSON.stringify(params))
    },
    async initList (row) {
      let arr = []
      arr = JSON.parse(JSON.stringify([...row]))
      arr.map(item => {
        if (item.id) {
          let str = item.id
          item.check_point_2_id = item.id
          console.log(str, 'item.idssssssssss')
          const numStr = str.replace(/\D/g, "");
          const num = parseInt(numStr);
          item.id = num

        }

        if (item.child.length > 0) {
          item.child.map(childitem => {
            if (childitem.check_point_3_id) {
              let str = childitem.check_point_3_id
              const numStr = str.replace(/\D/g, "");
              const num = parseInt(numStr);
              childitem.id = num
            }
          })
        }
      })

      this.tableData = arr
      console.log(this.tableData, 'this.tableData')
    }
    // async save () {
    //   await collect(this.form)
    //   this.handleClose()
    //   this.$message.success('收藏成功，可前往错题本查看')
    // }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20rem;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4rem;
}

::v-deep .el-dialog {
  border-radius: 20rem;
  min-height: 600rem;
  margin-top: 198rem !important;
}

::v-deep .el-table th,
.el-table tr {
  background: #f6f6f6;
}
::v-deep .cell {
  // text-indent: 60rem;
  display: flex;

  font-size: 20rem;
}

.checkpoint {
  font-weight: bold;
  color: #333333;
  font-size: 20rem;
}
.btn {
  width: 140rem;
  height: 44rem;
  background: #2196f3;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20rem;
  font-weight: bold;
  color: #ffffff;
  text-indent: 0;
  cursor: pointer;
  margin-right: 12rem;
}
</style>